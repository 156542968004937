<template>
  <div class="bg">
    <Head ref="head" />
    <div class="chinaImport">
      <div class="importCon">
        <h1>La manera más fácil y segura de importar desde China</h1>
        <h2>
          Sigue nuestra guía de usuario paso a paso y comienza a importar los
          productos que tu negocio necesita, sin complicaciones y con total
          seguridad.
        </h2>
        <div class="goToRegister" @click="$fun.routerToPage('/register')">
          Regístrate ahora
        </div>
      </div>
    </div>
    <div class="quickGuideIndex">
      <h1>Índice de la guía rápida</h1>
      <div class="indexContent">
        <p><a href="#Paso1">1. 🔍Encuentra y selecciona tus productos</a></p>
        <p><a href="#Paso2">2. 🛒Empezar un nuevo pedido</a></p>
        <p><a href="#Paso3">3. 🧾Cotización y pago del pedido</a></p>
        <p><a href="#Paso4">4. 📦Logística en China</a></p>
        <p><a href="#Paso5">5. ✈️Envío, importación y entrega del pedido</a></p>
      </div>
    </div>
    <div class="rakumartHowToOperate">
      <h2>Antes de empezar,</h2>
      <h1>Cómo funciona Rakumart</h1>
      <p>
        Importar con Rakumart lo cambia todo. Antes de comenzar con los pasos a
        seguir, es importante entender algunos conceptos sobre su
        funcionamiento.
      </p>
      <div class="operationKey">
        <span class="icon">🔑</span>
        <span class="otext">Podemos señalar 3 claves principales</span>
      </div>
      <div class="operationSteps">
        <div class="stepsContent">
          <span class="num">1.</span>
          <span class="stepName">Cotización</span>
          <p>
            Para conocer la disponibilidad y los costes de la mercancía de un
            pedido, consultamos toda la información con el fabricante. Recibimos
            dicha información en un plazo de 24 horas.
          </p>
        </div>
        <div class="stepsContent">
          <span class="num">2.</span>
          <span class="stepName">Logística en China</span>
          <p>
            Realizamos el pedido al fábrica y la mercancía es transportada a
            nuestros almacenes en China donde realizamos un control de calidad
            estándar, los servicios adicionales que requiera y preparamos el
            envío.
          </p>
        </div>
        <div class="stepsContent">
          <span class="num">3.</span>
          <span class="stepName">Importación</span>
          <p>
            Somos nosotros quienes realizamos la importación desde China hasta
            España. Por lo tanto, no tienes que preocuparte por trámites ni
            pagos de impuestos, ya que todo está incluido.
          </p>
        </div>
      </div>
    </div>

    <div class="pageRow stepIntroBox selectProduct">
      <div id="Paso1" style="position: relative; top: -150px"></div>
      <div class="stepIntro">
        <h2>Paso #1</h2>
        <h1>🔍Encuentra y selecciona tus productos</h1>
        <p>
          En Rakumart encontrarás una gran cantidad de opciones a tu
          disposición. Con más de 100.000 fabricantes, seguro que podemos
          ayudarte a encontrar lo que tu negocio necesita.
        </p>
      </div>
      <div class="stepIntroContent">
        <div class="stepIntroContentRow">
          <img
            class="jieshao1"
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479aadf413f9.png"
            alt=""
          />
        </div>
        <div class="stepIntroContentRow">
          <div class="stepIntroContentTextBox" style="margin-bottom: 30px">
            <h1>Usa la barra de búsqueda</h1>
            <p>
              ✅ Introduce las palabras clave del nombre del producto o la URL
              en
              <a class="blurLink" href="https://www.1688.com">1688.com</a> del
              producto que buscas.
            </p>
            <p>
              Nuestro motor de búsqueda traduce automáticamente al idioma chino
              para realizar la búsqueda. Es posible que la traducción de las
              palabras clave que introduzcas no sea correcta, por eso
              recomendamos la búsqueda por imagen.
            </p>
            <p>
              ✅ Haz clic en el icono de la cámara y selecciona la imagen del
              producto que estás buscando.
            </p>
          </div>
        </div>
        <div class="stepIntroContentRow">
          <img
            class="jieshao2"
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479ab8f6113c.gif"
            alt=""
          />
        </div>
        <div class="stepIntroContentRow">
          <div class="stepIntroContentTextBox">
            <h1>Añade tus productos al carrito</h1>
            <p>
              Una vez en la ficha de producto podrás encontrar los detalles del
              mismo, imágenes, características, precios por unidad y
              especificaciones.
            </p>
            <p>
              ✅ Selecciona las especificaciones tales como color, medidas,
              tipos… y la cantidad de unidades que deseas cotizar.
            </p>
            <p>
              ✅ Después haz clic en el botón de
              <span class="greenUnderLine">Añadir al carrito</span>.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="pageRow stepIntroBox startNewOrder">
      <div id="Paso2" style="position: relative; top: -150px"></div>
      <div class="stepIntro">
        <h2>Paso #2</h2>
        <h1>Empezar un nuevo pedido🛒</h1>
        <p>
          En Rakumart un nuevo pedido se inicia con la cotización por parte de
          nuestro equipo en China de los productos que hayas seleccionado.
        </p>
      </div>
      <div class="stepIntroContentRow">
        <img
          class="jieshao3"
          src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479abc66f2a5.gif"
          alt=""
        />
      </div>
      <div class="stepIntroContentRow">
        <div class="stepIntroContentTextBox">
          <h1>Carrito de compras</h1>
          <p>
            Selecciona los productos que deseas cotizar en un nuevo pedido y haz
            clic en el botón de
            <span class="greenUnderLine">Empezar nuevo pedido.</span>
          </p>
        </div>
      </div>
      <div class="stepIntroContentRow">
        <img
          class="jieshao4"
          src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479abf4b30f8.gif"
          alt=""
        />
      </div>
      <div class="stepIntroContentRow">
        <div class="stepIntroContentTextBox">
          <h1>Detalles del pedido</h1>
          <p>
            Selecciona los <b>servicios adicionales</b> que requieras para tus
            productos. Haz clic en el desplegable y elige entre el servicio y la
            cantidad.
          </p>
          <p>
            También puedes escribir otros servicios o
            <b>requisitos especiales</b> que quieras solicitar en la cotización,
            por ejemplo, packaging personalizado, personalización de producto
            con tu marca, etc.
          </p>
          <p>
            Nuestros agentes se pondrán en contacto contigo para concretar los
            detalles.
          </p>
        </div>
      </div>
      <div class="yellowRow">
        <span class="icon">📒</span>
        <h1>Requisitos especiales</h1>
        <p>
          ¿Necesitas personalizar algún elemento de los productos? Detalla lo
          más claro posible los requisitos especiales que necesitas en tus
          productos. Un agente experto de nuestro equipo se pondrá en contacto
          contigo para concretar los detalles antes de tramitar la cotización
          con el fabricante.
        </p>
      </div>
      <div class="stepIntroContentRow">
        <div class="stepIntroContentTextBox">
          <h1>Solicitar cotización</h1>
          <p>
            Una vez completado, haz clic en el botón
            <span class="greenUnderLine">Solicitar cotización</span> para que
            nuestro equipo pueda empezar a tramitar el coste de tus productos en
            China.
          </p>
        </div>
        <img
          class="jieshao6"
          :src="require('@/assets/img/jieshao6.svg')"
          alt=""
        />
      </div>
      <div class="stepIntroContentRow">
        <img
          class="jieshao7"
          src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479ac9dd3354.jpg"
          alt=""
        />
      </div>
    </div>

    <div class="pageRow stepIntroBox row1">
      <div id="Paso3" style="position: relative; top: -150px"></div>
      <div class="stepIntro">
        <h2>Paso #3</h2>
        <h1>🧾Cotización y pago del pedido</h1>
        <p>
          Tras la confirmación de la solicitud de cotización de tu pedido,
          nuestro equipo de agentes en China se comunica con el fabricante para
          proporcionar la información y los datos necesarios para calcular los
          costes del pedido.
        </p>
      </div>
      <div class="stepIntroContentRow">
        <img
          class="jieshao8"
          src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479acdf947a6.gif"
          alt=""
        />
      </div>
      <div class="stepIntroContentRow">
        <div class="stepIntroContentTextBox">
          <h1>Cotización y detalles del pago</h1>
          <p>
            En un plazo de 24 horas aproximadamente, recibirás un correo
            electrónico con la <b>cotización del pedido</b> y la cotización de
            los <b>gastos de envío</b> para cada método de transporte
            disponible.
          </p>
          <p>
            Podrás acceder a la cotización en cualquier momento, desde el inicio
            de tu Panel de Control haciendo clic en
            <span class="greenUnderLine">Pedidos pendiente de pago</span>.
          </p>
        </div>
      </div>
      <div class="stepIntroContentRow">
        <img
          class="jieshao9"
          src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479ad15405a2.gif"
          alt=""
        />
      </div>
      <div class="stepIntroContentRow">
        <div class="stepIntroContentTextBox">
          <h1>Selección del método de transporte</h1>
          <p>
            Antes de proceder al pago deberás elegir el método de transporte que
            más te convenga. Los métodos de transporte son los siguientes:
          </p>
          <p>✅ Transporte marítimo</p>
          <p>✅ Transporte aéreo</p>
          <p>✅ Transporte terrestre</p>
          <p>✅ Transporte ferroviario</p>
          <p>
            En cada opción encontrarás el desglose de costes y el tiempo de
            entrega aproximado de cada método de envío.
          </p>
        </div>
      </div>
      <div class="yellowRow">
        <span class="icon">💡</span>
        <h1>Volumen mínimo de transporte</h1>
        <p>
          Los métodos de transporte elegibles dependerán de las características
          de tu pedido (dimensiones, peso exacto de los productos, etc.). Si el
          pedido no supera los volúmenes mínimos para algún método de
          transporte, este aparecerá a 0 y no se podrá seleccionar.
        </p>
      </div>
      <div class="stepIntroContentRow">
        <img
          class="jieshao10 jieshao"
          src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479ad4e70f90.gif"
          alt=""
        />
      </div>
      <div class="stepIntroContentRow">
        <div class="stepIntroContentTextBox">
          <h1>Pago del pedido</h1>
          <p>
            Una vez seleccionado el método de transporte, se podrá proceder al
            pago del pedidos. Haz clic en el botón
            <span class="greenUnderLine">Proceder al pago</span> para acceder a
            la pasarela de pago.
          </p>
          <p>El desglose de costes de los productos incluye:</p>
          <p>✅ Valor de los productos</p>
          <p>✅ Comisión</p>
          <p>✅ Servicios adicionales (si los hay)</p>
          <p>✅ Logística en China (Control de calidad incluido)</p>
          <p>El desglose de costes del envío incluye:</p>
          <p>✅ Coste transporte internacional</p>
          <p>✅ Aranceles</p>
          <p>✅ IVA</p>
          <p>✅ Envío nacional</p>
        </div>
      </div>
      <div class="querenRow">
        <img
          class="jieshao11"
          :src="require('@/assets/img/jieshao11.svg')"
          alt=""
        />
        <h1>Pedido realizado correctamente</h1>
        <p>
          Cuando el pago ha sido realizado correctamente, nuestro equipo hace el
          pedido a fábrica a la espera de recibirlo en nuestros almacenes en
          China.
        </p>
      </div>
    </div>

    <div class="pageRow stepIntroBox row2">
      <div id="Paso4" style="position: relative; top: -150px"></div>
      <div class="stepIntro">
        <h2>Paso #4</h2>
        <h1>📦Logística en China</h1>
        <p>
          La mercancía se pondrá en marcha desde el fabricante hasta nuestros
          almacenes en China. Una vez recepcionada, nuestro equipo realiza un
          control de calidad estándar, los servicios adicionales que requiera y
          la prepará para el envío internacional.
        </p>
      </div>
      <div class="stepIntroContentRow">
        <img
          class="jieshao jieshao12"
          src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479ad9ec8cfa.gif"
          alt=""
        />
      </div>
      <div class="stepIntroContentRow">
        <div class="stepIntroContentTextBox">
          <h1>Control de calidad</h1>
          <p>
            El control de calidad estándar que realizamos cuando recibimos la
            mercancía desde fábrica incluye las siguientes comprobaciones:
          </p>
          <p>
            ✅ <b>Comprobación de la entrada del pedido</b>: verificamos que el
            producto recibido coincide con el producto que hemos pedido.
          </p>
          <p>
            ✅ <b>Comprobación de daños visibles</b>: revisamos visualmente el
            producto para detectar cualquier daño o defecto evidente.
          </p>
          <p>
            ✅ <b>Verificación de cualquier diferencia</b> importante entre la
            página del producto y el producto recibido: comprobamos que el
            producto recibido coincide con la descripción y las especificaciones
            que aparecen en la página del producto.
          </p>
        </div>
      </div>
      <div class="stepIntroContentRow">
        <img
          class="jieshao jieshao13"
          src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479ae05edd02.gif"
          alt=""
        />
      </div>
      <div class="stepIntroContentRow">
        <div class="stepIntroContentTextBox">
          <h1>Manipulación y servicios adicionales</h1>
          <p>
            En el caso de que el pedido contemple servicios adicionales, nuestro
            equipo procederá a la realización de los mismos una vez verificado
            el control de calidad. Después, la mercancía se prepara para la
            recogida del envío internacional.
          </p>
        </div>
      </div>
      <div class="stepIntroContentRow">
        <img
          class="jieshao jieshao14"
          src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479ae4ee1ced.gif"
          alt=""
        />
      </div>
      <div class="yellowRow">
        <span class="icon">💡</span>
        <h1>Almacenamiento en China disponible</h1>
        <p>
          Rakumart ofrece almacenamiento en China gratuito a nuestros clientes.
          De esta manera es posible almacenar la mercancía e ir importando según
          las necesidades de cada cliente y su negocio.
        </p>
      </div>
    </div>

    <div class="pageRow stepIntroBox row3">
      <div id="Paso5" style="position: relative; top: -150px"></div>
      <div class="stepIntro">
        <h2>Paso #5</h2>
        <h1>✈️Envío, importación y entrega del pedido</h1>
        <p>
          Empieza la importación. La mercancía pone rumbo a España mediante el
          método de transporte seleccionado.
        </p>
      </div>
      <div class="stepIntroContentRow">
        <img
          class="jieshao hei400"
          src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479ae875d706.gif"
          alt=""
        />
      </div>
      <div class="stepIntroContentRow">
        <div class="stepIntroContentTextBox">
          <h1>Envío internacional</h1>
          <p>
            Cuando la logística en China a terminado, nuestro equipo gestiona
            todos los trámites pertinentes para el transporte internacional a la
            espera de la recogida de la mercancía desde nuestros almacenes.
          </p>
          <p>
            En este proceso se generará un código de envío internacional que
            pondremos a tu disposición para el seguimiento correspondiente de la
            mercancía.
          </p>
        </div>
        <img
          class="jieshao16"
          :src="require('@/assets/img/jieshao16.svg')"
          alt=""
        />
      </div>
      <div class="hei70"></div>
      <div class="stepIntroContentRow">
        <div class="stepIntroContentTextBox">
          <h1>Despacho de aduanas</h1>
          <p>
            Al llegar a España, las mercancías deben pasar por trámites
            aduaneros. Todos los trámites correspondientes y procedimientos de
            pago de impuestos serán tramitados por Rakumart. En este punto
            Rakumart se encarga por ti de todos los tramites y burocracias.
          </p>
          <p>
            Nuestros clientes también podrán elegir la opción de realizar el
            despacho de aduanas por ellos mismos o mediante un tercero.
          </p>
        </div>
        <img
          class="jieshao17"
          :src="require('@/assets/img/jieshao16.svg')"
          alt=""
        />
      </div>
      <div class="hei70"></div>
      <div class="stepIntroContentRow">
        <div class="stepIntroContentTextBox">
          <h1>Envío nacional y entrega</h1>
          <p>
            Con la mercancía ya en nuestros <b>almacenes de Barcelona</b>,
            nuestro partner logístico nacional se encargará de transportar el
            pedido hasta la dirección de envío elegida<b> para la entrega</b>.
          </p>
          <p>
            Después de comprobar la mercancía y haber completado tu pedido, nos
            gustaría saber tu opinión.
          </p>
          <p>
            Puedes contactar con tu agente asignado para resolver cualquier duda
            que pudieras tener sobre tu pedido.
          </p>
          <p>¡Estamos a tu disposición!</p>
        </div>
      </div>
    </div>
    <div class="pageRow stepIntroBox row4">
      <div class="greenRow">
        <div class="icon">🏆</div>
        <h1>
          Con Rakumart podrás disfrutar de
          <span style="color: #77a518">ventajas únicas</span> a la hora de
          importar desde China:
        </h1>
        <p>
          <img
            decoding="async"
            class="emoji"
            role="img"
            draggable="false"
            src="https://s.w.org/images/core/emoji/14.0.0/svg/2705.svg"
            alt="✅"
          />
          Rakumart es la única plataforma de compra e importación desde China en
          la que tienes las <b>mismas condiciones</b> que te ofrece un
          <b>proveedor español</b>.
        </p>
        <p>
          <span style="color: var(--e-global-color-1792e67)"
            ><img
              decoding="async"
              class="emoji"
              role="img"
              draggable="false"
              src="https://s.w.org/images/core/emoji/14.0.0/svg/2705.svg"
              alt="✅"
            />
            Cotizamos los productos de tus pedidos directamente con fabricantes
            y proveedores, eliminando intermediarios y
            <b>reduciendo costes</b>.</span
          >
        </p>
        <p>
          <span style="color: var(--e-global-color-1792e67)"
            ><img
              decoding="async"
              class="emoji"
              role="img"
              draggable="false"
              src="https://s.w.org/images/core/emoji/14.0.0/svg/2705.svg"
              alt="✅"
            />
            Compra a <b>diferentes proveedores en un mismo pedido</b>.
            Gestionamos todas las necesidades de tus productos y preparamos toda
            tu mercancía en <b>un solo envío internacional</b>.</span
          >
        </p>
        <p>
          <span style="color: var(--e-global-color-1792e67)"
            ><img
              decoding="async"
              class="emoji"
              role="img"
              draggable="false"
              src="https://s.w.org/images/core/emoji/14.0.0/svg/2705.svg"
              alt="✅"/>
            Sourcing, compra, personalización, servicios adicionales, control de
            calidad, logística. <b>¡Todo en uno!</b> Nuestros clientes pueden
            disfrutar todo el proceso de importación sin moverse del sitio.<br
          /></span>
        </p>
        <p>
          <span style="color: var(--e-global-color-1792e67)"
            ><img
              decoding="async"
              class="emoji"
              role="img"
              draggable="false"
              src="https://s.w.org/images/core/emoji/14.0.0/svg/2705.svg"
              alt="✅"
            /> </span
          >Acompañamiento, asesoramiento, servicio de atención al cliente y
          posventa <b>en español</b>.
        </p>
      </div>
    </div>
    <div class="pageRow stepIntroBox row5">
      <div class="rowTitle">Preguntas frecuentes</div>
      <div class="row5Opt">
        <h1>¿Cómo puedo comprar muestras?</h1>
        <hr />
        <p>
          Para la compra de muestras de un producto, sigue el mismo proceso
          descrito en esta guía de uso. En la caja de comentarios del pedido
          escribe la palabra ‘<strong>Muestras</strong>‘.
        </p>
        <p>
          Es importante tener en cuenta que
          <strong>la cantidad mínima de muestras</strong> que se pueden
          adquirir,
          <strong>está sujeta a la disponibilidad del fabricante</strong>.
        </p>
      </div>
      <div class="row5Opt">
        <h1>¿Puedo seguir el estado de un pedido?</h1>
        <hr />
        <p>
          Si, podrás tener un control completo sobre la situación de tus pedidos
          y hacer un seguimiento detallado de cada uno de ellos.
        </p>
      </div>
      <div class="row5Opt">
        <h1>¿Hay cantidad mínima de compra?</h1>
        <hr />
        <p>
          Rakumart es una plataforma de importación y no ofrecemos venta al
          detalle.&nbsp;La cantidad mínima de
          <b>compra la determina el fabricante</b>.
        </p>
        <p>
          Debes tener en cuenta que los precios que se muestran las fichas de
          producto solo incluyen el costo del mismo, y no incluyen otros gastos
          como envío, impuestos o aranceles. Por lo tanto, comprar pocas
          unidades no sería rentable si se importa desde China, a menos que se
          esté comprando muestras para evaluar la calidad antes de realizar
          una&nbsp;<span style="color: var(--e-global-color-1792e67)"
            >futura</span
          ><span style="color: var(--e-global-color-1792e67)"
            >&nbsp;importación.</span
          >
        </p>
      </div>
      <div class="row5Opt">
        <h1>¿Qué tipo de pagos se aceptan?</h1>
        <hr />
        <p>
          Puedes pagar con <b>tarjeta de crédito</b>&nbsp;(pasarela de pago
          Banco Santander) o bien, realizar una <b>transferencia bancaria</b> a
          nuestro número de cuenta del Banco Santander.
        </p>
      </div>
      <div class="row5Opt">
        <h1>¿Cuál es el coste del envío?</h1>
        <hr />
        <p>
          El coste del envío dependerá del volumen de cada pedido y del método
          de transporte seleccionado. Por lo general el método de transporte
          marítimo es el más económico, seguido del transporte terrestre y el
          transporte aéreo.
        </p>
        <div class="hei15"></div>
        <p>El coste del envío se desglosa en los siguientes puntos:</p>
        <ul class="listFont">
          <li>Transporte internacional (China a España)</li>
          <li>Logística en España</li>
          <li>Aranceles</li>
          <li>IVA</li>
        </ul>
        <div class="hei28"></div>
        <p>
          Para conocer los detalles del coste de un producto en concreto,
          realiza la solicitud de cotización, en 24 horas nuestro equipo te hará
          llegar la información con los costes asociados a tu pedido.
        </p>
      </div>
      <div class="row5Opt">
        <h1>¿Cuánto tardaré en recibir mi pedido?</h1>
        <hr />
        <p>
          En el tiempo de entrega, desde que sea realiza el pedido hasta que el
          cliente lo recibe, hay que tener 3 factores en cuenta:
        </p>
        <ul class="listFont">
          <li>
            <b>Tiempo de transporte en China</b>, desde fábrica hasta nuestros
            almacenes. Dependerá de la distancia entre la fábrica y nuestros
            almacenes y del tiempo de manipulación de la mercancía por parte del
            fabricante. El tiempo estimado puede ser de 2 a 4 días.
          </li>
          <li>
            El tiempo del proceso del&nbsp;<b
              >control de calidad y preparación del envío internacional</b
            >&nbsp;en nuestros almacenes. Este proceso puede durar
            aproximadamente 48 horas dependiendo el tipo y cantidad de
            mercancía.
          </li>
          <li>
            Los tiempos de entrega desde que la mercancía sale de nuestros
            almacenes en China&nbsp;<b>dependerán del método de transporte</b
            >&nbsp;seleccionado por el cliente.
          </li>
        </ul>
        <div class="hei28"></div>
        <p>Tiempo aproximado de entrega mediante:</p>
        <ul class="listFont">
          <li>Transporte Aéreo: entre 10-20 días</li>
          <li>Transporte Terrestre: entre 35-45 días</li>
          <li>Transporte Marítimo: entre 60-70 días</li>
          <li>Transporte Ferroviario: entre 45-55 días</li>
        </ul>
        <div class="hei28"></div>
        <p>
          También hay que añadir el tiempo de envío nacional, de 24 a 48 horas,
          desde nuestros almacenes en España hasta la dirección de entrega.
        </p>
        <p>
          *Los tiempos de transporte hacen referencia específicamente al periodo
          requerido por la compañía logística para transportar la mercancía
          desde su punto de origen hasta su punto de destino. Por lo tanto, el
          «tiempo de transporte» no debe confundirse con el «tiempo de entrega
          total». Estos tiempos son aproximados y pueden variar dependiendo de
          factores externos como el clima, regulaciones aduaneras, entre otros.
        </p>
      </div>
    </div>
    <div class="pageRow stepIntroBox row6">
      <div class="linkBox">
        <div class="box1">
          <h1>¿Tienes alguna duda más específica?</h1>
          <p>Visita nuestro Centro de Ayuda para buscar las respuestas.</p>
        </div>
        <div class="btn" @click="$fun.routerToPage('/centro-de-ayuda')">
          Visitar Centro de Ayuda
        </div>
      </div>
    </div>
    <Foot />
  </div>
</template>
<script>
import Foot from "../../components/foot/Foot.vue";
import Head from "../../components/head/HomePageTop.vue";
export default {
  components: {
    Foot,
    Head,
  },
  data() {
    return {};
  },
  computed: {
    evn() {
      // || location.origin == 'http://localhost:8080'
      if (
        location.origin == "https://www.rakumart.es" ||
        location.origin == "https://rakumart.eu" ||
        location.origin == "www.rakumart.es" ||
        location.origin == "http://www.rakumart.es"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {},
  methods: {
    openChat() {
      this.$fun.clickJs();
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}
.hei70 {
  height: 70px;
}
.hei20 {
  height: 20px;
}
.hei15 {
  height: 15px;
}
.blurLink {
  color: #121299;
  font-weight: bolder;
}
.greenUnderLine {
  text-decoration: underline;
  color: #06ac1c;
  font-weight: bolder;
}
.pageRow {
  padding: 80px 0;
  background-color: #f9f9f9;
  &:nth-child(2n) {
    background-color: white;
  }
}
.greenRow {
  width: 620px;
  padding: 50px;
  border-radius: 4px;
  background-color: #f4fee7;
  .icon {
    font-size: 45px;
    font-weight: 700;
    line-height: 1.2em;
    text-align: center;
    margin-bottom: 20px;
  }
  h1 {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.4em;
    margin-bottom: 34px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.8em;
    display: block;
    margin-bottom: 14.4px;
    img {
      height: 1em;
      width: 1em;
      margin: 0 0.07em;
      vertical-align: -0.1em;
    }
  }
}
.chinaImport {
  height: 504px;
  margin-top: -20px;
  background-image: linear-gradient(46deg, #f2f7f7 30%, #f7fcf1 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  .importCon {
    width: 690px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      font-size: 45px;
      font-weight: 700;
      line-height: 1.2em;
      text-align: center;
      margin-bottom: 20px;
    }
    h2 {
      text-align: center;
      color: #070027;
      font-size: 18px;
      font-weight: 400;
      line-height: 33px;
      margin-bottom: 34px;
    }
    .goToRegister {
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      background-color: #1a289d;
      border-radius: 50px;
      width: 205px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
    }
  }
}
.quickGuideIndex {
  height: 675px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    margin-bottom: 40px;
    color: #070027;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.5em;
  }
  .indexContent {
    padding: 60px;
    background-color: white;
    p {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.5em;
      color: #404040;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 10px;
      }
    }
  }
}
.rakumartHowToOperate {
  padding: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 34px;
  h2 {
    color: #070027;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.5em;
    margin-bottom: 13px;
  }
  h1 {
    color: #070027;
    font-size: 45px;
    font-weight: 700;
    line-height: 1.2em;
    margin-bottom: 20px;
  }
  p {
    width: 600px;
    text-align: center;
    color: #404040;
    font-family: "Open Sans", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.8em;
    margin-bottom: 34px;
  }
  .operationKey {
    span {
      display: block;
      text-align: center;
    }
    .icon {
      font-size: 40px;
      line-height: 1.5em;
    }
    .otext {
      color: #070027;
      font-size: 22px;
      font-weight: 700;
      line-height: 1.5em;
      margin-bottom: 20px;
    }
  }
  .operationSteps {
    display: flex;
    justify-content: center;
    .stepsContent {
      flex: 0 0 360px;
      padding: 30px;
      span {
        display: block;
      }
      .num {
        color: #a5a5a5;
        font-size: 60px;
        font-weight: 700;
        line-height: 1.2em;
      }
      .stepName {
        color: #404040;
        font-size: 30px;
        font-weight: 700;
        text-decoration: underline;
        line-height: 1.5em;
        margin-bottom: 20px;
      }
      p {
        width: 100%;
        text-align: left;
      }
    }
  }
}
.stepIntroBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .stepIntro {
    width: 600px;
    padding-bottom: 30px;
    padding: 10px 10px 30px;
    h2 {
      color: #070027;
      font-size: 30px;
      font-weight: 700;
      line-height: 1.5;
      margin-bottom: 15px;
      text-align: center;
    }
    h1 {
      color: #070027;
      font-size: 45px;
      font-weight: 700;
      line-height: 1.2em;
      text-align: center;
      margin-bottom: 20px;
    }
    p {
      text-align: center;
      color: #404040;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.8em;
      margin-bottom: 14px;
    }
  }
  .stepIntroContent {
  }
}
.stepIntroContentRow {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
}
.yellowRow {
  border-radius: 4px;
  width: 580px;
  padding: 60px;
  margin-bottom: 50px;
  background-color: #fffcf2;

  .icon {
    font-size: 55px;
    font-weight: 500;
    line-height: 1.5em;
    margin-bottom: 10px;
    display: block;
  }
  h1 {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.5em;
    color: #404040;
    margin-bottom: 30px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.8em;
    margin-bottom: 14px;
  }
}
.querenRow {
  width: 580px;
  border-radius: 4px;
  padding: 50px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 28px;
    font-weight: 700;
    line-height: 1.2em;
    margin-bottom: 15px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.8em;
    margin-bottom: 14px;
    text-align: center;
  }
}
.stepIntroContentTextBox {
  width: 580px;
  padding: 0 20px;
  h1 {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.5em;
    color: #404040;
    margin-bottom: 30px;
  }
  p {
    color: #404040;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.8em;
    margin-bottom: 14px;
  }
}
.selectProduct {
  padding-bottom: 50px;
  .jieshao1 {
    width: 910px;
    height: 600px;
    margin-bottom: 20px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 13px 8px -6px rgba(0, 0, 0, 0.08);
  }
  .jieshao2 {
    width: 710px;
    height: 357px;
    margin-bottom: 20px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 21px 27px -8px rgba(0, 0, 0, 0.08);
  }
}
.startNewOrder {
  .jieshao3 {
    width: 710px;
    height: 350px;
    margin-bottom: 20px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 21px 27px -8px rgba(0, 0, 0, 0.08);
    margin: 20px;
  }
  .jieshao4 {
    width: 710px;
    height: 395px;
    margin: 20px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 21px 27px -8px rgba(0, 0, 0, 0.08);
  }
  .jieshao6 {
    position: absolute;
    width: 280px;
    height: 280px;
    rotate: 210deg;
    top: 110px;
    left: -107px;
    transform: rotateY(180deg) scale(0.83);
    z-index: 1;
  }
  .jieshao7 {
    width: 710px;
    height: 395px;
    margin: 20px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 21px 27px -8px rgba(0, 0, 0, 0.08);
  }
}
.jieshao {
  width: 710px;
  height: 338px;
  margin-bottom: 20px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 21px 27px -8px rgba(0, 0, 0, 0.08);
}
.hei400 {
  height: 400px;
}
.hei28 {
  height: 28px;
}
.row1 {
  .jieshao8 {
    width: 710px;
    height: 338px;
    margin-bottom: 20px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 21px 27px -8px rgba(0, 0, 0, 0.08);
  }
}
.row2 {
  .jieshao12 {
    height: 400px;
  }
  .jieshao13 {
    height: 400px;
  }
  .jieshao14 {
    height: 400px;
  }
}
.row3 {
  .jieshao16 {
    position: absolute;
    width: 280px;
    height: 280px;
    rotate: 220deg;
    top: 135px;
    left: -117px;
    transform: rotateY(180deg) scale(0.73);
    z-index: 1;
  }
  .jieshao17 {
    position: absolute;
    width: 280px;
    height: 280px;
    rotate: 143deg;
    top: 166px;
    left: 411px;
    transform: scale(0.73);
    z-index: 1;
  }
}
.row5 {
  .rowTitle {
    font-size: 45px;
    font-weight: 700;
    line-height: 1.2em;
    padding-bottom: 60px;
  }
  .row5Opt {
    padding: 50px;
    background-color: white;
    width: 950px;
    border-radius: 4px;
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
    h1 {
      font-size: 22px;
      font-weight: 700;
      line-height: 1.2em;
      margin-bottom: 20px;
    }
    hr {
      margin: 15px 0 35px;
      border-top: #dedede solid 1px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.8em;
      margin-bottom: 14px;
    }
    .listFont {
      padding-left: 40px;
      li {
        list-style: disc;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.8em;
      }
    }
  }
}
.row6 {
  .linkBox {
    width: 100%;
    background-color: #f8f9fa;
    padding: 50px;
    .box1 {
      padding: 10px;
      h1 {
        font-size: 21px;
        font-weight: 500;
        line-height: 1.5em;
        text-align: center;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.8em;
        text-align: center;
        margin-bottom: 14px;
      }
    }
    .btn {
      font-size: 15px;
      font-weight: 600;
      fill: #1a73e8;
      color: #1a73e8;
      background-color: #ffffff00;
      border-style: solid;
      border-width: 2px 2px 2px 2px;
      border-color: #1a73e8;
      padding: 20px 80px 20px 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 80px;
      width: max-content;
      border-radius: 4px;
      margin: 0 auto;
      transition: 0.3s;
      cursor: pointer;
      &:hover {
        background-color: #1a73e8;
        color: white;
      }
    }
  }
}
</style>
