<template>
  <div class="footerBackground">
    <div class="footerLinkBox">
      <div class="boxOpt">
        <p class="boxTitle">{{ $fanyi("Únete 和 Rakumart") }}</p>
        <div class="boxSpan" style="line-height: 1.8em; cursor: default">
          {{ $fanyi("开始享受从中国进口Rakumart的好处吧。") }}
        </div>
        <div class="findGood" @click="$fun.routerToPage('/ventajas')">
          {{ $fanyi("发现所有的好处") }}
        </div>
        <button class="registerBtn" @click="$fun.routerToPage('/register')">
          {{ $fanyi("注册或登入") }}
        </button>
      </div>

      <!-- <div class="boxOpt">
        <img
          class="logoImg"
          src="../../assets/hometop/logo-rakumart-europe.svg"
          alt=""
        />
        <p>soporte@rakumart.es</p>
        <p>Horario de atención:<br />L-V (09:00h-18:00h)</p>
        <div class="imgBox">
          <img
            style="width: 36px; height: 36px"
            src="../../assets/newImg/footlist/dibuicon/visa.svg"
            alt=""
          />
          <img
            style="width: 36px; height: 22px"
            src="../../assets/newImg/footlist/dibuicon/mastercard.svg"
            alt=""
          />
          <img
            style="width: 36px; height: 22px"
            src="../../assets/newImg/footlist/dibuicon/maestro.svg"
            alt=""
          />
        </div>
      </div> -->
      <div class="boxOpt"></div>
      <div class="boxOpt">
        <p class="boxTitle">Rakumart</p>
        <div class="boxSpan" @click="$fun.routerToPage('/quienes-somos')">
          {{ $fanyi("我们是谁") }}
        </div>
        <div class="boxSpan" @click="$fun.routerToPage('/logistica')">
          {{ $fanyi("订单运输中") }}
        </div>
        <div
          class="boxSpan"
          @click="$fun.routerToPage('/servicios-adicionales')"
        >
          {{ $fanyi("附加服务说明") }}
        </div>
        <div class="boxSpan" @click="$fun.routerToPage('/comisiones-rakumart')">
          {{ $fanyi("委员会") }}
        </div>
      </div>
      <div class="boxOpt">
        <p class="boxTitle">{{ $fanyi("用户们") }}</p>
        <div class="boxSpan" @click="openUserCenter">
          {{ $fanyi("个人中心") }}
        </div>
        <div class="boxSpan" @click="$fun.routerToPage('/user/order')">
          {{ $fanyi("订单管理") }}
        </div>
        <div
          class="boxSpan"
          @click="
            $fun.routerToPage('/user/wuliuStatus?status=deliveryInTransit')
          "
        >
          {{ $fanyi("运输中的订单") }}
        </div>
        <div class="boxSpan" @click="$fun.routerToPage('/user/invoiceHistory')">
          {{ $fanyi("发票") }}
        </div>
      </div>
      <div class="boxOpt">
        <p class="boxTitle">{{ $fanyi("帮助") }}</p>
        <div class="boxSpan" @click="$fun.routerToPage('/centro-de-ayuda')">
          {{ $fanyi("帮助中心") }}
        </div>
        <div class="boxSpan" @click="$fun.routerToPage('/guia-de-usuario')">
          {{ $fanyi("快速用户指南") }}
        </div>
        <div
          class="boxSpan"
          @click="$fun.routerToPage('/garantias-y-posventa')"
        >
          {{ $fanyi("保修及售后服务") }}
        </div>
        <div class="boxSpan" @click="$fun.routerToPage('/atencion-al-cliente')">
          {{ $fanyi("对客户的关注") }}
        </div>
      </div>
    </div>
    <div class="showInfoImg">
      <div class="bankIcon">
        <img :src="require('@/assets/foot-img/payIcon/visa-1.svg')" alt="" />
        <img
          :src="require('@/assets/foot-img/payIcon/mastercard-1.svg')"
          alt=""
        />
        <img
          :src="require('@/assets/foot-img/payIcon/mastercard-1.svg')"
          alt=""
        />
        <img
          :src="require('@/assets/foot-img/payIcon/bank-trasnfer.svg')"
          alt=""
        />
      </div>
      <div class="socialNetworkingPlatform">
        <a
          href="https://www.facebook.com/people/Rakumartes/100086130939868/"
          target="_blank"
        >
          <img src="../../assets/foot-img/facebook (1).svg" alt="" />
        </a>
        <a href="https://www.instagram.com/rakumart.es/" target="_blank"
          ><img src="../../assets/foot-img/instagram.svg" alt=""
        /></a>

        <a href="https://www.tiktok.com/@rakumart" target="_blank"
          ><img src="../../assets/foot-img/tik-tok.svg" alt=""
        /></a>

        <a href="https://www.youtube.com/@rakumartespana4548" target="_blank"
          ><img src="../../assets/foot-img/youtube (1).svg" alt=""
        /></a>
      </div>
    </div>
    <div class="plateNumber">
      <span
        >©Rakumart 2023 Todos los derechos reservados - Elosung Europe S.A.
        Carrer de Francesc Teixidó, 43, 08918 Badalona, Barcelona, España</span
      >
      <div class="qita">
        <span
          @click="$fun.routerToPage('/condiciones-generales-de-contratacion')"
        >
          {{ $fanyi("一般条件") }}
        </span>
        <span @click="$fun.routerToPage('/aviso-legal')">
          {{ $fanyi("法律通知") }}
        </span>
        <span @click="$fun.routerToPage('/politica-privacidad')">
          {{ $fanyi("用户协议和隐私政策") }}
        </span>
        <span @click="$fun.routerToPage('/politica-de-cookies')">
          {{ $fanyi("Cookie 政策") }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      islogin: false,
    };
  },
  created() {
    if (this.$route.path == "/login") {
      this.islogin = false;
    } else {
      this.islogin = true;
    }
  },
  methods: {
    openUserCenter() {
      if (localStorage.getItem("user_token")) {
        this.$fun.routerToPage("/user/index");
      } else {
        this.$store.commit("getactivePage", "/user/index");
        this.$store.commit("getshowLoginDialog", true); //打开登录弹窗
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/mixin.scss";

.footerBackground {
  width: max-content;
  min-width: 100%;
  background-color: #f8f9fa;
  border-top: solid 1px #e8e8e8;
  .footerLinkBox {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    padding: 80px 0 45px;

    .boxOpt {
      flex: 0 0 210px;
      // padding: 10px;
      &:first-child {
        flex: 0 0 280px;
        margin-right: auto;
      }
      .logoImg {
        width: 160px;
        height: 40px;
        margin-bottom: 20px;
      }

      .imgBox {
        display: flex;
        align-items: center;

        img {
          margin-right: 16px;
        }
      }
      .findGood {
        margin-bottom: 30px;
        text-decoration: underline;
        font-size: 15px;
        font-weight: 500;
        color: black;
        cursor: pointer;
      }
      p {
        color: #767676;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.8em;
        margin-bottom: 14.4px;
      }

      .boxTitle {
        color: #404040;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 40px;
        cursor: default;
      }
      .registerBtn {
        font-size: 13px;
        font-weight: 600;
        color: #ffffff;
        background-color: #1a289d;
        border-radius: 4px 4px 4px 4px;
        padding: 12px 15px 12px 15px;
      }
      .boxSpan {
        font-size: 14px;
        font-weight: 500;
        color: #767676;
        margin-bottom: 15px;
        cursor: pointer;
        line-height: 1.5;
      }
    }
  }
  .showInfoImg {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 45px;
    .bankIcon {
      display: flex;
      align-items: center;
      img {
        width: 50px;
        height: 25px;
        margin-right: 20px;
      }
    }
    .socialNetworkingPlatform {
      img {
        width: 30px;
        height: 30px;
        margin-left: 15px;
      }
    }
  }
  .plateNumber {
    width: 1400px;
    height: 100px;
    margin: 0 auto;
    border-top: solid 1px #e1e1e1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      color: #404040;
      font-size: 13px;
      font-weight: 500;
    }
    .qita {
      span {
        cursor: pointer;
        margin: 7.5px;
      }
    }
  }
}
</style>
